
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { gsap } from 'gsap';
import { StaticContentType } from '../enums/static-content-type.enum';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { About as AboutModel } from '../models/static-contents/about.interface';
import { translateApi } from '@/utils/translate-api';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import StaffMember from '../models/staff-members/staff-member.class';
import { BModal } from 'bootstrap-vue';
import { TranslatedProperty } from '@/models/shared/translated-property.class';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import { StaffType } from '../enums/staffType.enum';

const staticContentsModule = namespace('staticContents');
const staffModule = namespace('staffMembers');

@Component({
  components: { VideoPlayer },
  filters: {
    translateApi,
  },
})
export default class AboutUs extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => AboutModel;

  @staffModule.Action('fetchUnpaginated')
  fetchStaffMembers!: () => StaffMember[];

  @staffModule.Getter('all')
  staffMembers!: StaffMember[];

  about: AboutModel | null = null;
  modalMember: StaffMember | null = null;

  $refs!: Vue['$refs'] & {
    memberModal: BModal;
  };

  onEnter() {
    gsap.from('#frame1', {
      duration: 1.5,
      x: -300,
      ease: 'back',
      stagger: 0.25,
      delay: 0.1,
    });
    gsap.from('#frame2', {
      duration: 1.5,
      y: 300,
      ease: 'back',
      stagger: 0.25,
      delay: 0.1,
    });
    gsap.from('#frame3', {
      duration: 1.5,
      y: -300,
      ease: 'back',
      stagger: 0.25,
      delay: 0.1,
    });
    gsap.from('#frame4', {
      duration: 1.5,
      y: 300,
      ease: 'back',
      stagger: 0.25,
      delay: 0.1,
    });
    gsap.from('#frame5', {
      duration: 1.5,
      x: 300,
      ease: 'back',
      stagger: 0.25,
      delay: 0.1,
    });
  }

  get headerHtml() {
    if (this.about) {
      return `<h2 class="text-white">${translateApi(
        this.about.titleText.value,
        this.$i18n.locale,
      )}</h2><p class="text-white">${translateApi(
        this.about.introText.value,
        this.$i18n.locale,
      )}</p>`;
    } else return '';
  }

  getProfilePictureUrl(url) {
    return `url(${url})`;
  }

  getSequence(sequence: number) {
    const staffMember = this.staffMembers.find(val => {
      if (val.sequence === sequence) {
        return val;
      }
      return false;
    });
    return staffMember;
  }

  checkHasExpertMembers(): boolean {
    return this.staffMembers.some(member => member.staffType === StaffType.EXPERT);
  }

  openMemberModal() {
    this.$refs.memberModal.show();
  }

  get translation() {
    if (this.modalMember) {
      const res = translateApi(
        this.modalMember.description as TranslatedProperty,
        this.$i18n.locale,
      );
      return res;
    }
    return false;
  }

  onMemberMoreInfo(sequence: number) {
    this.modalMember = this.staffMembers.find(member => member.sequence === sequence) || null;

    if (this.modalMember) {
      this.openMemberModal();
    }
  }

  onMemberModalClosing() {
    this.modalMember = null;
  }

  async mounted() {
    this.onEnter();
  }
  async created() {
    await this.fetchStaffMembers();

    if (!this.getStaticContentByType(StaticContentType.About)) {
      await this.fetchStaticContent(StaticContentType.About);
    }

    this.about = this.getStaticContentByType(StaticContentType.About);
    this.setHeaderHtml(this.headerHtml);
  }
}
