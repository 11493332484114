var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-container',[(_vm.localeBaseUrlPath !== '/en')?_c('video-player'):_vm._e(),_c('h3',{staticClass:"mt-5 mb-4"},[_vm._v(_vm._s(_vm.$t('about.teamMembers')))]),_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('b-row',{staticClass:"cursor-pointer",attrs:{"id":"frame1"},on:{"click":function($event){return _vm.onMemberMoreInfo(0)}}},[(_vm.getSequence(0))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item1",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(0).profilePicture.url),
            })},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(0).firstName)+" "+_vm._s(_vm.getSequence(0).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(0).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1),_c('b-row',{staticClass:"py-2 cursor-pointer",attrs:{"id":"frame2"},on:{"click":function($event){return _vm.onMemberMoreInfo(1)}}},[(_vm.getSequence(1))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item2",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(1).profilePicture.url),
            })},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(1).firstName)+" "+_vm._s(_vm.getSequence(1).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(1).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"7"}},[_c('b-row',{staticClass:"pl-0 pl-md-2 pb-2 cursor-pointer",attrs:{"id":"frame3"},on:{"click":function($event){return _vm.onMemberMoreInfo(2)}}},[(_vm.getSequence(2))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item3",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(2).profilePicture.url),
            })},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(2).firstName)+" "+_vm._s(_vm.getSequence(2).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(2).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1),_c('b-row',{staticClass:"pb-2 ml-0 ml-md-2 grid-team-member1"},[(_vm.getSequence(3))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item4 cursor-pointer",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(3).profilePicture.url),
            }),attrs:{"id":"frame5","md":"6"},on:{"click":function($event){return _vm.onMemberMoreInfo(3)}}},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(3).firstName)+" "+_vm._s(_vm.getSequence(3).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(3).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e(),(_vm.getSequence(4))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item5 cursor-pointer",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(4).profilePicture.url),
            }),attrs:{"id":"frame5","md":"6"},on:{"click":function($event){return _vm.onMemberMoreInfo(4)}}},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(4).firstName)+" "+_vm._s(_vm.getSequence(4).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(4).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1)],1)],1),(_vm.checkHasExpertMembers)?_c('h3',{staticClass:"mt-5 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('about.experts'))+" ")]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"7"}},[_c('b-row',{staticClass:"grid-expert-item1 cursor-pointer",attrs:{"id":"frame1"},on:{"click":function($event){return _vm.onMemberMoreInfo(5)}}},[(_vm.getSequence(5))?_c('b-col',{class:_vm.getSequence(5) ? 'd-flex align-items-end w-100 pl-4 pl-md-3 grid-item3' : '',style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(5).profilePicture.url),
            })},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(5).firstName)+" "+_vm._s(_vm.getSequence(5).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(5).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1),_c('b-row',{staticClass:"py-2 grid-expert-item2 cursor-pointer",attrs:{"id":"frame2"}},[(_vm.getSequence(6))?_c('b-col',{class:_vm.getSequence(6) ? 'd-flex align-items-end w-100 grid-item4' : '',style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(6).profilePicture.url),
            }),attrs:{"md":"6"},on:{"click":function($event){return _vm.onMemberMoreInfo(6)}}},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(6).firstName)+" "+_vm._s(_vm.getSequence(6).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(6).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e(),(_vm.getSequence(7))?_c('b-col',{class:_vm.getSequence(7) ? 'd-flex align-items-end w-100 grid-item5 cursor-pointer' : '',style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(7).profilePicture.url),
            }),attrs:{"md":"6"},on:{"click":function($event){return _vm.onMemberMoreInfo(7)}}},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(7).firstName)+" "+_vm._s(_vm.getSequence(7).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(7).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-row',{staticClass:"pl-0 pl-md-2 pb-2",attrs:{"id":"frame3"}},[(_vm.getSequence(8))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item2 cursor-pointer",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(8).profilePicture.url),
            }),on:{"click":function($event){return _vm.onMemberMoreInfo(8)}}},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(8).firstName)+" "+_vm._s(_vm.getSequence(8).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(8).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1),_c('b-row',{staticClass:"pl-0 pl-md-2 pb-2"},[(_vm.getSequence(9))?_c('b-col',{staticClass:"d-flex align-items-end w-100 grid-item1 cursor-pointer",style:({
              '--profilePicture': _vm.getProfilePictureUrl(_vm.getSequence(9).profilePicture.url),
            }),attrs:{"id":"frame5"},on:{"click":function($event){return _vm.onMemberMoreInfo(9)}}},[_c('div',{staticClass:"text-white"},[_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.getSequence(9).firstName)+" "+_vm._s(_vm.getSequence(9).lastName)+" ")]),_c('p',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.getSequence(9).function,_vm.$i18n.locale))+" ")]),_c('div',[_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.$t('general.readMore'))+" "),_c('inline-svg',{staticClass:"mb-1 ml-3 blue",attrs:{"src":require(`@/assets/img/arrows/arrow-right.svg`)}})],1)])])]):_vm._e()],1)],1)],1)],1),_c('b-modal',{ref:"memberModal",attrs:{"id":"memberModal","size":"lg","header-class":"card-header","centered":"","body-class":"trainer-modal-body","hide-footer":"","hide-header":"","content-class":"trainer-modal"},on:{"hide":_vm.onMemberModalClosing}},[(_vm.modalMember)?[_c('div',{staticClass:"trainer-modal-image"},[_c('img',{attrs:{"src":_vm.modalMember.profilePicture.url,"alt":"member-image"}})]),_c('div',{staticClass:"trainer-modal-content"},[_c('h6',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.modalMember.firstName)+" "+_vm._s(_vm.modalMember.lastName))]),_c('p',{staticClass:"blue-500"},[_vm._v(" "+_vm._s(_vm._f("translateApi")(_vm.modalMember.function,_vm.$i18n.locale))+" ")]),_c('div',{staticClass:"text-justify",domProps:{"innerHTML":_vm._s(_vm.translation)}})])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }